// /* ---------------------------------- */
// /* Footer */
// /* ---------------------------------- */
.dz--main-footer{
    padding: 50px 20px;
    text-align: left;
    .dz--small{
        font-size: 12px;
        text-align: left;
        color: $c_brand-gray-02;
        margin: 10px 0 0 0;
    }
    h5{
        font-weight: 700;
        font-size: 18px;
    }
    a{
        display: block;
        margin: 10px 0 0 0;
        color: $c_brand-gray-02;
        text-decoration: none;
    }
}