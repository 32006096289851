// /* ---------------------------------- */
// /* Demo */
// /* ---------------------------------- */
// Page specificity{
.page-demo{
  .dz--scribble{
    margin: 0 0 30px 0;
  }
}
.dz--hidden{display: none;opacity: 0;}
form{
  margin: 0 0 30px 0;
  display: block;
  background-color: $c_white;
  border-radius: 5px;
  border: 1px solid $c_brand-gray-01;
  padding: 30px;
  h4{
    font-weight: 700;
    text-align: left;
    margin: 0 0 15px 0;
  }
  p{
    text-align: left;
    color: $c_brand-gray-02;
    line-height: 1.5;
    margin: 0 0 30px 0;
  }
  label{
    display: block;
    font-size: 14px;
    text-align: left;
    margin: 0 0 15px 0;
    color: $c_brand-gray-02;
    font-family: $f_sans;
    span{color: $c_brand-green-02;}
  }
  input{
    display: block;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid $c_brand-gray-01;
    background-color: $c_white;
    font-family: $f_sans;
    font-size: 16px;
    @extend .dz--input-width;
    &:focus{
      outline: 1px solid $c_brand-green-02;
    }
  }
  button{
    padding: 20px;
    font-size: 16px;
    background-color: $c_brand-green-02;
    color: $c_white;
    text-align: center;
    border-radius: 3px;
    display: block;
    text-decoration: none;
    font-family: $f_sans;
    border: 0;
    @extend .dz--input-width;
    @include easing(200ms);
    &:hover{
        @include easing(200ms);
        background-color: $c_brand-green-03;
    }
  }
  .dz--form-group{margin: 0 0 30px 0;}
  .dz--inline-form{
    label,input{
      display: inline-block;
      text-align: left;
      width: auto;
      margin: 0;
    }
    label{margin: 0 0 0 5px;}
    margin: 0 0 30px 0;
    text-align: left;
  }
}
.dz--demo-notes{
  .col-xs-2{padding: 0 15px 0 0;}
  h5{
    font-size: 25px;
    font-weight: 700;
    line-height: 1.2;
    text-align: left;
    margin: 0 0 30px 0;
  }
  p{
    margin: 0 0 20px;
    text-align: left;
    line-height: 1.5;
    padding: 0 0 20px 0;
    border-bottom: 1px solid $c_brand-gray-01;
    color: $c_brand-gray-02;
  }
}