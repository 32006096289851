// /* ---------------------------------- */
// /* Success */
// /* ---------------------------------- */
.dz--masthead-seo-pages{
    @extend .dz--masthead-large;
    a{text-decoration: none;color: $c_brand-green-02;word-break: break-all;}
    p{
        text-align: left;
        font-size: 18px;
        line-height: 1.5;
        margin: 0 0 20px 0;
        color: $c_brand-gray-02;
    }
    h3{
        text-align: left;
        font-size: 25px;
        font-weight: 700;
        margin: 0 0 20px 0;
    }
    .dz--seo-content-block{
        padding: 30px;
        border: 1px solid $c_brand-gray-00;
        border-radius: 3px;
        margin: 0 0 30px 0;
    }
    .dz--inline-scribble{
        margin: 0 0 30px 0;
    }
    ul{
        li{
            text-align: left;
            border-bottom: 2px solid $c_brand-gray-01;
            padding: 0 0 10px 0;
            margin: 0 0 20px 0;
            color: $c_brand-gray-02;
            line-height: 1.5;
            font-size: 18px;
            ul{
                margin: 10px 0 0 20px;
                li{
                    display: block;
                }
            }
        }
    }
}