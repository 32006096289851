/* ---------------------------------- */
/* Masthead */
/* ---------------------------------- */
.dz--masthead-large{
    padding: 80px 20px;
    h1{
        font-size: 70px;
        font-weight: 700;
        margin: 0 0 30px 0;
        line-height: 1.1;
        position: relative;
        z-index: 1;
    }
    h2{
        font-size: 18px;
        color: $c_brand-gray-02;
        line-height: 1.5;
        margin: 0 0 30px 0;
        a{color: $c_brand-green-02;text-decoration: none;}
    }
    a.dz--masthead-btn{
        padding: 20px;
        background-color: $c_brand-green-02;
        color: $c_white;
        text-align: center;
        border-radius: 3px;
        display: block;
        text-decoration: none;
        margin: 0 0 30px 0;
        @include easing(200ms);
        &:hover{
            @include easing(200ms);
            background-color: $c_brand-green-03;
        }
    }
    ul.dz--small-list{
        li{
            display: inline-block;
            font-size: 12px;
            color: $c_brand-gray-02;
            margin: 0 10px;
            text-align: center;
            span{color: $c_brand-green-02;}
        }
    }
}
.dz--hero-stats{
    padding: 50px 20px;
    h1{
        font-size: 40px;
        font-weight: 700;
        margin: 0 0 30px 0;
        line-height: 1.1;
    }
    h2{
        font-size: 18px;
        color: $c_brand-gray-02;
        line-height: 1.5;
    }
    .dz--stat-block{
        padding: 50px 0px;
        h2{
            font-weight: 200;
            color: $c_brand-green-02;
            font-size: 80px;
        }
        p{
            font-size: 16px;
            color: $c_brand-gray-02;
            line-height: 1.5;
        }
    }
}
.dz--magic-features{
    @extend .dz--hero-stats;
    .dz--magic-block{
        padding: 30px;
        border: 1px solid $c_brand-gray-00;
        margin: 0 0 30px 0;
        border-radius: 3px;
        text-align: left;
    }
    img{display: block;padding: 0 0 20px 0;}
    h4{
        font-size: 16px;
        font-weight: 500;
        padding: 0 0 20px 0;
    }
    p{
        font-size: 16px;
        color: $c_brand-gray-02;
        line-height: 1.5;
    }
}
.dz--product-overview{
    @extend .dz--magic-features;
    background-image: linear-gradient(180deg, #FFFFFF 0%, lighten($c_highlight, 12%) 100%);
    padding: 70px 20px;
    .dz--product-block{
        text-align: left;
        padding: 30px;
        img.dz--ds{
            box-shadow: 0 5px 30px rgba(0,0,0,0.1);
            margin: 0;
            padding: 0;
            border-radius: 3px;
        }
    }
    .dz--ma{
        margin: 0 auto;
        padding: 50px 0 20px 0;
    }
}
.dz--main-cta{
    @extend .dz--magic-features;
    h1{
        border-bottom: 2px solid $c_brand-green-02;
        padding: 0 0 5px 0;
        display: inline-block;
    }
    .dz--main-cta-button{
        padding: 20px;
        background-color: $c_brand-green-02;
        color: $c_white;
        text-align: center;
        border-radius: 3px;
        display: inline-block;
        text-decoration: none;
        margin: 30px 0 0 0;
        @include easing(200ms);
        &:hover{
            @include easing(200ms);
            background-color: $c_brand-green-03;
        }
    }
}
.dz--mini-ig{
    max-height: 70px;
    width: auto;
    display: block;
    margin: 0 auto 20px auto;
}