/* ---------------------------------- */
/* Basics */
/* ---------------------------------- */
@import "basics/grid";
@import "basics/reset";
@import "basics/fonts";
@import "basics/colors";
@import "basics/mixins";

/* ---------------------------------- */
/* Pages & Components */
/* ---------------------------------- */
@import "components/global";
@import "components/nav";
@import "components/home";
@import "components/about";
@import "components/footer";
@import "components/demo";
@import "components/product";
@import "components/personas";
@import "components/success";
@import "components/seo";

// /* ---------------------------------- */
// /* Responsive */
// /* ---------------------------------- */
@import "basics/responsive";