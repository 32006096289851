// /* ---------------------------------- */
// /* About */
// /* ---------------------------------- */
.page-about{
    .dz--masthead-large{
        p{
            text-align: left;
            color: $c_brand-gray-02;
            line-height: 1.5;
            font-size: 18px;
            margin: 0 0 30px 0;
            a{text-decoration: none;color: $c_brand-green-02;}
        }
    }
}