$bp-01: 1140px;
$bp-02: 996px;
// col-sm
$bp-03: 865px;
// col-xs
$bp-04: 767px;

/* ---------------------------------- */
/* 1140 */
/* ---------------------------------- */

@media all and (max-width: $bp-01){
    
}

/* ---------------------------------- */
/* 996 */
/* ---------------------------------- */

@media all and (max-width: $bp-02){
    .dz--highlight-block{display: none;}
    .dz--masthead-large{
        padding: 50px 20px;
        h1{font-size: 55px;}
        h2{font-size: 16px;}
        ul.dz--small-list{
            max-width: 140px;
            margin: 0 auto;
            li{
                display: block;
                margin: 0 0 10px 0;
                text-align: left;
            }
        }
    }
    .dz--product-block-feature{
        padding: 50px 20px;
        h1{font-size: 55px;}
        p{font-size: 16px;}
        img.dz--product-page-icon{max-width: 30px;}
        img.dz--demo-gif{margin: 0 0 30px 0;}
        ul{li{&:last-of-type{border: 0;padding: 0;margin: 0;}}}
    }
}

/* ---------------------------------- */
/* 865 */
/* ---------------------------------- */

@media all and (max-width: $bp-03){
    
}

/* ---------------------------------- */
/* 767 */
/* ---------------------------------- */
// col-xs
@media all and (max-width: $bp-04){
    .col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
        padding-right: 0;
        padding-left: 0;
    }
    .dz--main-nav{
        padding: 30px 20px;
        .dz--right-nav{
            display: none;
        }
        .dz--mobile-menu-wrapper{
            display: block;
            cursor: pointer;
        }
        .dz--show-mobile{
            margin: 20px 0 0 0;
            display: block;
            padding: 20px;
            background-color: $c_white;
            box-shadow: 0 5px 20px rgba(0,0,0,0.2);
            border-radius: 8px;
            a{
                display: block;
                padding: 0;
                margin: 0 0 20px 0;
                &:last-of-type{margin: 0;}
            }
            a.dz--nav-btn{
                padding: 0;
                border: 0;
                &:hover{
                    padding: 0;
                    border: 0;
                }
            }
        }
    }
    .dz--product-block-feature{
        h1{font-size: 35px;}
    }
    .dz--masthead-large{
        h1{font-size: 35px;}
        a.dz--masthead-btn{
            display: inline-block;
        }
    }
    .dz--hero-stats{
        padding: 30px 20px;
        h1{font-size: 25px;line-height: 1.2;}
        h2{font-size: 16px;}
        .dz--stat-block{
            padding: 30px;
            background-color: $c_white;
            border-radius: 8px;
            box-shadow: 0 5px 20px rgba(0,0,0,0.15);
            margin: 20px 0;
            h2{font-size: 50px}
        }
    }
    .dz--main-footer{
        .dz--m-p{margin: 0 0 30px 0;}
    }
}