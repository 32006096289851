/* ---------------------------------- */
/* Nav */
/* ---------------------------------- */
.dz--main-nav{
  padding: 50px 20px;
  border-top: 3px solid $c_brand-green-02;
  .dz--mobile-menu-wrapper{display: none;}
  .dz--right-nav{
    a{
      text-decoration: none;
      color: $c_brand-gray-03;
      margin: 0 0 0 30px;
      @include easing(200ms);
      &:hover{
        @include easing(200ms);
        color: $c_brand-green-02;
      }
    }
    a.dz--nav-btn{
      padding: 0 0 5px 0;
      border-bottom: 2px solid $c_brand-green-01;
      font-weight: 700;
      @include easing(200ms);
      &:hover{
        @include easing(200ms);
        border-bottom: 2px solid transparent;
      }
    }
    a.dz--active{font-weight: 700;}
  }
}