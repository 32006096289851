@mixin easing($s){
    -moz-transition: all $s ease;
    -webkit-transition: all $s ease;
    -o-transition: all $s ease;
    transition: all $s ease
}
.dz--input-width{
    width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
}
// [class*='col-']{
//     padding-left: 0;
//     padding-right: 0;
// }