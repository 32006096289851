/* ---------------------------------- */
/* Global*/
/* ---------------------------------- */
body{
    font-family: $f_sans;
    font-size: 16px;
    color: $c_brand-gray-03;
    img{
        max-width: 100%;
        height: auto;
    }
}