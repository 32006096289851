// /* ---------------------------------- */
// /* Personas */
// /* ---------------------------------- */
.dz--masthead-gradient{
    @extend .dz--masthead-large;
    background-image: linear-gradient(134deg, #00E0AB 0%, #136B98 100%);
    h5{
        font-size: 14px;
        font-weight: 700;;
        color: $c_white;
        margin: 0 0 20px 0;
        text-transform: uppercase;
    }
    h1{
        margin: 0 0 30px 0;
        color: $c_white;
    }
    h2{
        font-size: 20px;
        color: $c_white;
        margin: 0;
        padding: 0;
    }
}
.dz--basic-hero{
    @extend .dz--masthead-large;
    h1{font-size: 40px;}
    p{
        line-height: 1.5;
        color: $c_brand-gray-02;
        text-align: left;
        margin: 0 0 20px 0;
    }
}
.dz--people-hero{
    @extend .dz--basic-hero;
    .dz--who-block{
        padding: 30px;
        border: 1px solid $c_brand-gray-00;
        margin: 0 0 30px 0;
        border-radius: 3px;
    }
    img{
        text-align: left;
        display: block;
        max-width: 50px;
        height: auto;
    }
    p{
        border: 0;
        padding: 0;
        margin: 0 0 30px 0;
        text-align: left;
        &:last-of-type{margin: 0;}
    }
    h3{
        font-size: 30px;
        font-weight: 300;
        text-align: left;
        margin: 20px 0 30px 0;
    }
}