// /* ---------------------------------- */
// /* Product */
// /* ---------------------------------- */
.dz--product-block-feature{
    padding: 100px 20px;
    text-align: left;
    h4{
        font-size: 12px;
        text-transform: uppercase;
        color: $c_brand-gray-02;
        font-weight: 700;
        margin: 0 0 10px 0;
        text-align: left;
    }
    h1{
        font-size: 60px;
        font-weight: 700;
        margin: 0 0 30px 0;
        text-align: left;
        line-height: 1.1;
    }
    p{
        font-size: 18px;
        color: $c_brand-gray-02;
        margin: 0 0 30px 0;
        text-align: left;
        line-height: 1.5;
    }
    a{text-decoration: none;color: $c_brand-green-02;}
    ul{
        li{
            text-align: left;
            color: $c_brand-gray-03;
            border-bottom: 2px solid $c_brand-gray-01;
            padding: 0 0 20px 0;
            margin: 0 0 20px 0;
            line-height: 1.5;
        }
    }
    img.dz--demo-gif{
        border: 2px solid $c_brand-green-02;
        border-radius: 3px;
    }
    img.dz--product-page-icon{
        max-width: 50px;
        height: auto;
        margin: 0 0 20px 0;
    }
}
.dz--gray-bg{
    background-color: $c_brand-gray-00;
}