// Brand Colors
$c_brand-green-01: #18E9B8;
$c_brand-green-02: #24AD8D;
$c_brand-green-03: #075E49;
$c_brand-gray-00: #F3F3F3;
$c_brand-gray-01: #E7E7E7;
$c_brand-gray-02: #858585;
$c_brand-gray-03: #010101;
$c_brand-gray-04: #FFFCF4;
$c_brand-light-01: #E0FFF8;
$c_brand-light-02: #CEE7E1;
$c_brand-light-03: #9DBEB6;

// Basic Colors
$c_black: $c_brand-gray-03;
$c_white: #fff;
$c_highlight: #FFEBAF;